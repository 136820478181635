<template>
  <div>
    <v-row v-if="isVisible" dense class="mx-1 mt-2">
      <v-col cols="12">
        <gli-text-field
          :label="$t('Annotation')"
          v-model="annotation.name"
          @change="changeName()"
        ></gli-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import constants from '~/shared/constants';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'AnnotationPanel',
  props: ['map', 'draw'],

  data() {
    return {
      annotation: {
        name: undefined,
        id: undefined
      },
      isVisible: false
    };
  },

  mounted() {
    this.map.on('draw.create', (e) => {
      const annotation = e.features.find(
        (feature) => feature.properties._type === constants.FEATURE_TYPES.ANNOTATION
      );
      if (!annotation) {
        return;
      }

      annotation.properties.name =
        this.annotation.name && this.annotation.name.length
          ? this.annotation.name
          : constants.DEFAULT_ANNOTATION;
      this.addAnnotation(annotation);
      this.saveAnnotation();
    });

    this.map.on('draw.selectionchange', (e) => {
      const selectedAnnotation =
        e.features &&
        e.features.length === 1 &&
        e.features[0].properties._type === constants.FEATURE_TYPES.ANNOTATION
          ? e.features[0]
          : undefined;

      if (selectedAnnotation) {
        this.annotation.name = selectedAnnotation.properties.name;
        this.annotation.id = selectedAnnotation.id;
        this.isVisible = true;
      } else {
        // if not clicked on an annotation
        this.annotation.name = undefined;
        this.annotation.id = undefined;
        this.isVisible = false;
      }
    });
  },

  computed: {
    ...mapGetters('annotation', ['editedAnnotation']),
    ...mapGetters('level', ['editedLevel']),
    ...mapGetters('status', ['currentDrawMode']),
    ...mapFields('annotation', 'editedAnnotation.feature.properties.name')
  },

  methods: {
    ...mapActions('annotation', {
      addAnnotation: 'addNew',
      editAnnotation: 'edit',
      saveAnnotation: 'save'
    }),
    ...mapMutations('feature', { updateCurrentFeatures: 'updateCurrentFeatures' }),

    async changeName(keepSelection = true) {
      if (!this.annotation.name) {
        this.annotation.name = constants.DEFAULT_ANNOTATION;
      }

      if (this.annotation.id) {
        // if an existing annotation is modified
        await this.editAnnotation(this.annotation.id);

        this.editedAnnotation.feature.properties.name = this.annotation.name;

        await this.saveAnnotation(this.annotation.id);

        this.updateCurrentFeatures(this.editedLevel.id);

        if (keepSelection) {
          // keep the selection of modified annotation
          this.draw.changeMode('simple_select', { featureIds: [this.annotation.id] });
        }
      }
    }
  },

  watch: {
    async currentDrawMode() {
      if (this.currentDrawMode === constants.DRAW_MODES.ANNOTATION) {
        if (this.annotation.name !== undefined) {
          await this.changeName(false);
        }
        this.isVisible = true;
        this.annotation.id = undefined;
        this.annotation.name = constants.DEFAULT_ANNOTATION;
      } else {
        this.isVisible = false;
      }
    }
  }
};
</script>
